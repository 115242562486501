/**
 *
 * Created by lnz on 11/02/2017.
 * Call this plugin on $grid elemnts, specifying links selector in order to open modal when clicked
 *  e.g.
 *
 *  $('my-grid-selector').detailInModal({
 *       linksSelector: '.modal-link',
 *       modalId: '#my-modal-id',
 *       modalResponseSelector: '.my-resp-div-selecor'
 *  )};
 *
 *  Specify the modal ID and the container selector in which put the response
 *  otherwise the response will be injected inside the modal itself (usually Foundation modal has closing button inside,
 *  in this case it will be wiped out).
 *  You can pass a custom function that will be called after modal has fully opened (for instance to init a slider)
 *
 */
(function ($) {

  $.fn.detailInModal = function (options) {

    var settings = $.extend({
      modalId: '#modal',
      modalResponseSelector: '',
      linksSelector: '.links',
      customFunction: null,
      disabledOnSmall: true,
      disabledOnMedium: true
    }, options);

    var baseHref = document.URL;
    var mobileLimit = 'large';
    if (!settings.disabledOnSmall || !settings.disabledOnMedium) {
      if (!settings.disabledOnMedium) {
        mobileLimit = 'medium';
      }
      if (!settings.disabledOnSmall) {
        mobileLimit = 'small';
      }
    }

    return this.each(function () {
      var $grid = $(this);
      var $modal = $(settings.modalId);
      var $modalResponseSelector = (settings.modalResponseSelector) ? $modal.find(settings.modalResponseSelector) : $modal;

      if (Foundation.MediaQuery.atLeast(mobileLimit)) {
        $grid.on('click', settings.linksSelector, function (e) {
          e.preventDefault();
          var url = $(this).attr('href');
          $.ajax({
              url: url
            })
            .done(function (resp) {
              $modalResponseSelector.html(resp);

              $modal.foundation('open');
              if (window.history.replaceState) {
                window.history.replaceState({path: url}, 'News', url);
              }
            });
        });
      }

      $modal.on({
        'closed.zf.reveal': function () {
          if (window.history.replaceState) {
            window.history.replaceState({path: baseHref}, 'News page', baseHref);
          }
        },
        'open.zf.reveal': function () {
          if (settings.customFunction) {
            settings.customFunction();
          }
        }
      });
    });
  };
}(jQuery));