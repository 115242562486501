(function () {
  var ITALIAN_TEXT = '<p>Questo sito utilizza cookie di profilazione, propri o di terzi, per ricavare informazioni sull\'utilizzo delle pagine. Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie clicca sul link <a id="cookie-law-details-button" href="{{details_link}}">Maggiori Informazioni</a>. Chiudendo questo banner o cliccando su qualunque suo elemento acconsenti all’uso dei cookie. <a id="cookie-law-button" href="#">Chiudi</a></p>';
  var OTHER_TEXT = '<p>This site uses cookies to improve your navigation experience and to collect information on the way the site is used. For further details, see our data privacy policy <a id="cookie-law-details-button" href="{{details_link}}">here</a><br />By continuing to navigate this site, you implicitly accept our use of cookies. If you do not accept our use of cookies, you may leave this site now. <a id="cookie-law-button" href="#">OK</a></p>';

  var addEvent = function (element, evnt, funct) {
    if (element.attachEvent)
      return element.attachEvent('on' + evnt, funct);
    else
      return element.addEventListener(evnt, funct, false);
  };
  var setup = function () {
    var cookieLawDiv = document.getElementById('cookie-law'),
      cookieLawText,
      lng;
    lng = cookieLawLang.substring(0, 2);
    if (lng === 'it') {
      cookieLawText = ITALIAN_TEXT;
    } else {
      cookieLawText = OTHER_TEXT;
    }
    cookieLawDiv.innerHTML = '<div class="cookie-in">' + cookieLawText.replace(/{{details_link}}/g, '/' + lng + '/cookies-policy/') + '</div>';
  };
  var getCookie = function (c_name) {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
      x = x.replace(/^\s+|\s+$/g, "");
      if (x == c_name) {
        return unescape(y);
      }
    }
  };
  var setCookie = function (c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? "" : ";path=/; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
  };
  var toggleElement = function (element) {
    if (element.getAttribute('style') === 'display:none' || element.getAttribute('style') === 'display: none;' || element.getAttribute('style') === 'DISPLAY: none' || (typeof(element.getAttribute('style')) === 'object' && element.getAttribute('style').cssText === 'DISPLAY: none')) {
      if (typeof(element.getAttribute('style')) === 'object') {
        element.getAttribute('style').cssText = 'DISPLAY: block';
      } else {
        element.setAttribute('style', 'display:block');
      }
    } else {
      if (typeof(element.getAttribute('style')) === 'object') {
        element.getAttribute('style').cssText = 'DISPLAY: none';
      } else {
        element.setAttribute('style', 'display:none');
      }
    }
  };
  var checkCookie = function () {
    var cookieLawCookie = getCookie("cookieLawCookie"),
      cookieLawBar = document.getElementById('cookie-law');
    if (cookieLawCookie != null && cookieLawCookie != "") {
    } else {
      toggleElement(cookieLawBar);
    }
  };
  var cookieLawBarInit = function () {
    var button = document.getElementById('cookie-law-button'),
      cookieLawBar = document.getElementById('cookie-law');
    addEvent(button, 'click', function (e) {
      e.preventDefault();
      setCookie("cookieLawCookie", "CookiesAllowed", 365);
      toggleElement(cookieLawBar);

    });
  };
  var cookieLawBar = function () {
    setup();
    cookieLawBarInit();
    checkCookie();
  };

  (function () {
    var tId = setInterval(function () {
      if (document.readyState == "complete") onComplete()
    }, 11);

    function onComplete() {
      clearInterval(tId);
      cookieLawBar();
    }
  })();
})();