(function ($) {
    $(document).foundation();

    if ($('span.counter').length) {
        $('span.counter').counterUp();
    }

    var contentBody = $('#pl-main-wrapper');
    //Mobile Slidout menu
    var $slideoutToggler = $('.slideout-toggler');
    var slideout = new Slideout({
        'panel': document.getElementById('pl-main-wrapper'),
        'menu': document.getElementById('mobile-menu-panel'),
        'padding': 256,
        'tolerance': 70
    });
    slideout.on('beforeopen', function () {
        contentBody.addClass('pl-opened');
        // $slideoutToggler.addClass('tcon-transform');
    });
    slideout.on('beforeclose', function () {
        contentBody.removeClass('pl-opened');
        // $slideoutToggler.removeClass('tcon-transform');
    });
    // Mobile side menu Toggle button
    $slideoutToggler.on('click', function () {
        slideout.toggle();
    });

    // Removing click on Main menu top item
    // var menuItems = $('.pl-main-menu .is-dropdown-submenu-parent > a');
    // menuItems.on('click', function (e) {
    //     e.preventDefault();
    // })
}(jQuery));

